angular.module('fingerink')

    .directive('registerPay', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: 'web/public/register/directives/pay/pay.tpl.html',
            scope: {
                register: '=',
                parent: '='

            },
            controllerAs: 'controller',
            controller: 'registerPayCtrl'
        };
    })
    .controller('registerPayCtrl', function ($rootScope, $scope, $stateParams, payTPVService, $sce, $timeout, $translate) {
        var that = this;

        that.parent = $scope.parent;
        that.register = $scope.register;

        that.user = $scope.register.user;

        that.siguiente = function () {
            that.error = false;
        };


        that.coin = that.parent.paisesMap[that.user.company.country.id].coin;
        that.pais = that.parent.paisesMap[that.user.company.country.id];
        //that.precio = that.parent.precios[that.parent.paisesMap[that.user.company.country.id].coin];

        
        that.productosMap =  that.parent.productosMap;
        that.prices = that.parent.precios[that.coin];


        function checkIfFinished() {
            payTPVService.isTarjetaLoaded(that.cardTransaction.transaction).then(function (response) {
                if (response.data == "CORRECT") {
                    that.parent.doRegister(true);
                } else if (response.data == "ERROR") {
                    swal($translate.instant("Error añadiendo tarjeta"), $translate.instant("Esta tarjeta no se ha podido añadir. Por favor, inténtelo de nuevo más adelante"), "error");
                    userService.getIframeUrlTarjeta(that.register.user.company.id).then(function (response) {
                        that.cardTransaction = response.data;
                        that.url = $sce.trustAsResourceUrl(response.data.url);
                    });
                } else {
                    if (!that.destroy) {
                        that.timer = $timeout(checkIfFinished, 500);
                    }
                }
            });
        }

        function getIframeURL() {
            payTPVService.getIframeUrlTarjeta(that.register.user.company.id).then(function (response) {
                that.cardTransaction = response.data;
                that.url = $sce.trustAsResourceUrl(response.data.url);
            }, function () {
                getIframeURL();
            });

        }
        getIframeURL();



        $scope.$on("$destroy", function () {
            that.destroy = true;
            window.removeEventListener("message", receiveMessage);

            if (that.timer) {
                $timeout.cancel(that.timer);
            }
        });


        function receiveMessage(event) {
            if (event.origin == "https://app.fingerink.biz") {
                if (event.data == 'cardOk') {
                    checkIfFinished();
                }
                if (event.data == 'cardKo') {
                    swal($translate.instant("Error añadiendo tarjeta"), $translate.instant("Esta tarjeta no se ha podido añadir. Por favor, inténtelo de nuevo más adelante"), "error");
                    userService.getIframeUrlTarjeta(that.register.user.company.id).then(function (response) {
                        that.cardTransaction = response.data;
                        that.url = $sce.trustAsResourceUrl(response.data.url);
                    });
                }
            } else {
                return;
            }
        }
        window.addEventListener("message", receiveMessage, false);

    });
